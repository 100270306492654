import { DsSnackbarType } from '@design-system/feature/snackbar';
import { createAction, props } from '@ngrx/store';

export namespace RootActions {
  export const ShowSnackbar = createAction(
    '[TELEMATIC-LAB] SHOW_SNACKBAR',
    props<{ message: string; snackbar_type: DsSnackbarType }>(),
  );
  export const ShowErrorSnackbar = createAction(
    '[TELEMATIC-LAB] SHOW_ERROR_SNACKBAR',
  );

  export const SaveUsersMail = createAction(
    '[TELEMATIC-LAB] SAVE_USERS_MAIL',
    props<{ mail: string }>(),
  );
}
