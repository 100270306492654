import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteUndoModule } from '@ui-kit/form-control';

const sharedModules = [
  //Angular
  RouterModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  TranslateModule,
  //shared
  DsTableModule,
  DsFilterModule,
  DsFilterInputModule,
  DsSpacingModule,
  DsTextIndicatorModule,
  DsPlaceholderModule,
  DsTableLoadingModule,
  DsLoadingModule,
  DsPageModule,
  PdLetDirectiveModule,
  DsSnackbarComponent,
  DeleteUndoModule,
  //Material
  MatStepperModule,
  MatIconModule,
  MatTableModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTooltipModule,
  MatListModule,
  //cdk
  ClipboardModule,
];

@NgModule({
  imports: [...sharedModules],
  exports: [...sharedModules],
})
export class SharedTelematicLabModule {}
