<button
  mat-icon-button
  *ngIf="!showUndo"
  (click)="onRemove()"
  [disabled]="isDeleteDisabled"
  type="button"
>
  <mat-icon matTooltip="{{ 'general.delete-row' | translate }}"
    >delete</mat-icon
  >
</button>
<button
  mat-button
  color="warn"
  *ngIf="showUndo"
  (click)="onUndo()"
  type="button"
>
  {{ 'general.undo' | translate }}({{ countDownValue | async }})
</button>
