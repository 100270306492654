import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CountdownService } from './countdown.service';

@Component({
  selector: 'ui-kit-delete-undo',
  templateUrl: './delete-undo.component.html',
  styleUrls: ['./delete-undo.component.scss'],
  standalone: false,
})
export class DeleteUndoComponent implements OnInit {
  @Input() id: string | number;
  @Output() remove = new EventEmitter();
  @Input() isDeleteDisabled = false;
  showUndo = false;
  countDownValue: Observable<number> | null;
  constructor(private countdown: CountdownService) {}

  ngOnInit() {
    if (this.isDeleteDisabled === undefined) {
      this.isDeleteDisabled = false;
    }
    this.countDownValue = this.countdown.getCountDownValue(this.id);
    if (this.countDownValue) {
      this.showUndo = true;
    }
  }

  onRemove() {
    this.showUndo = true;
    this.remove.emit();
    this.countDownValue = this.countdown.getCountDownValue(this.id);
  }

  onUndo() {
    this.showUndo = false;
    this.countdown.undoCountDown(this.id);
  }
}
